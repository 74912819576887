<template>
  <v-list-item>
    <v-list-item-avatar size="32" style="margin-left: 0">
      <v-icon>$participants</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        {{ group.name }}
        <v-chip small class="ml-1 pa-2">{{ membersCount }}</v-chip>
      </v-list-item-title>
    </v-list-item-content>

    <v-list-item-action>
      <temeno-circle-button title="bearbeiten" @click="editGroup">
        $edit
      </temeno-circle-button>
    </v-list-item-action>

    <v-list-item-action>
      <temeno-circle-button title="löschen" @click="deleteGroup">
        $delete
      </temeno-circle-button>
    </v-list-item-action>

    <v-list-item-action>
      <temeno-circle-button
        :title="
          group.favorite ? 'aus Favoriten entfernen' : 'als Favorit markieren'
        "
        @click="toggleFavorite"
      >
        {{ group.favorite ? "$favorite_green" : "$favorite_white" }}
      </temeno-circle-button>
    </v-list-item-action>

    <v-list-item-action>
      <temeno-circle-button title="Videoanruf starten" @click="startVideoCall">
        $video_call
      </temeno-circle-button>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import api from "@/api";

export default {
  name: "NavigationGroupListItem",
  props: {
    group: {
      type: Object,
    },
  },
  components: {
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  computed: {
    membersCount () {
      return this.group.members.length;
    },
  },
  methods: {
    deleteGroup() {
      this.$store.dispatch("groups/delete", this.group.id);
    },
    editGroup() {
      this.$emit("edit", this.group);
    },
    startVideoCall() {
      console.log("starting video call with", this.group);
      const participants = this.group.members.map((member) => ({
        scope: "JOIN",
        type: "user",
        userId: member.id,
      }));
      const conference = {
        subject: "Direktanruf",
        participants,
      };
      api.createConference(conference).then((response) => {
        const { location } = response.headers;
        const id = Number(location.substring(location.lastIndexOf("/") + 1));
        api.inviteForConference(id).then(() => {
          this.$router.push({
            name: "conference",
            params: {
              id,
            },
          });
        });
      });
    },
    toggleFavorite() {
      this.$store.dispatch("groups/toggleFavorite", this.group);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.theme--dark {
  &.v-list-item {
    padding: 0 6px;

    .v-list-item__action {
      margin: 6px 0;
      display: none;
    }

    .v-list-item__avatar:first-child {
      margin-right: 10px;
    }

    &:hover {
      .v-list-item__action {
        display: inline-flex;
      }
    }
  }
}
</style>
